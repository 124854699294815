<template>
    <div class="betListModalWrap">
        <div class="modalHeader">
            <span>Bet List</span>
            <span class="closeBtn">
                <img
                    v-on:mouseup.stop="showModal('betList', false)"
                    src="@/assets/sabong/mobile/icon/btn-close.png"
                    alt="">
            </span>
        </div>
        <div class="userBetList">
            <div class="userBetListWrap">
                <div class="innerListWrap">
                    <!--class="winner" 로 승자 표시-->
                    <div
                        class="list"
                        v-for="data in totalBetList"
                        v-bind:key="data"
                        v-bind:class="{
                            'winner': currentGameResult == data['rooster']
                        }">
                        <div class="listTop">
                            <div class="user">
                                <div class="flegImg">
                                    <img
                                        v-bind:src="getFlagImage(data?.['currency_code'])"
                                        alt="flag">
                                </div>
                                <span class="userName">
                                    {{ setCollapsed(data?.['user_id']) }}
                                </span>
                            </div>
                            <div class="betRooster">
                                <!--class="meron" / class="wala"로 베팅한 팀을 표시할 수 있습니다.-->
                                <span v-bind:style="setBetLocale.bgcolor[data?.['rooster']]">
                                    {{ $t(setBetLocale.text[data?.['rooster']]) }}
                                </span>
                            </div>
                            <div class="bet">
                                <span
                                    class="yellow"
                                    v-numberFormat="calcRate(data?.['amount'] || 0, props.exchangeInfo)">
                                </span>
                                <span>{{ loginUser?.currency }}</span>
                            </div>
                        </div>
                        <div class="result">
                            <span class="resultName">
                                {{ $t('result') }}
                            </span>
                            <div class="resultBox">
                                <span
                                    class="yellow"
                                    v-numberFormat="calcRate(data?.['winAmount'] || 0, props.exchangeInfo, data)">
                                </span>
                                <span>{{ loginUser?.currency }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { showModal } from "@/js/modal/modals";
    import { getFlagImage } from "@/js/common";
    import { computed, defineProps } from "vue";
    import { betUsers } from "@/js/inGame/sabongInter";
    import { setCollapsed } from "@/js/utils";
    import { loginUser } from '@/js/reactive';
    import { calcRate } from "@/js/inGame/exchange";
    import { LOGO } from "@/js/const";

    const totalBetList = computed(() => {
        return betUsers['totalBetList'];
    });

    const setBetLocale = LOGO[location.hostname].betLocale;

    const props = defineProps({
        exchangeInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    });

</script>
<style scoped>
.betListModalWrap{
    position: absolute;
    z-index: 2;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 564px;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    border-radius: 14px;
    border: 2px solid #bbb;
    padding: 0 8px;
}
.betListModalWrap .modalHeader{
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 11px 0;
}
.betListModalWrap .modalHeader .closeBtn{
    position: absolute;
    right: 0;
    height: 28px;
}
.betListModalWrap .modalHeader .closeBtn img{
    width: 28px;
    height: 28px;
}
.userBetList{
    position: relative;
    width: auto;
}
.userBetListWrap{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.innerListWrap{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    color: #fff;
    text-align: center;
}

.innerListWrap .innerListWrap{
    height: 670px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
}
.innerListWrap .list{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    padding: 4px 4px 7px 4px;
    font-size: 14px;
    background: #5a5a5c;
    border-radius: 6px;
}
.innerListWrap .listTop{
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 14px;
}
.innerListWrap .list.winner{
    background: #26813b;
    border: 1px solid #6eff81;
}
.innerListWrap .user{
    display: flex;
    width: auto;
}
.innerListWrap .user .flegImg img{
    width: 16px;
    height: 16px;
}
.innerListWrap .bet{
    display: grid;
    justify-content: center;
}
.innerListWrap .result{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
.innerListWrap .result .resultBox{
    width: 160px;
    height: 20px;
    padding-right:6px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    background: #2b2b2c;
}
.innerListWrap .bet .yellow, .innerListWrap .result .yellow{
    color: #ffce23;
    width: 50%;
    text-align: right;
}
.innerListWrap .betRooster span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 24px;
    border-radius: 6px;
}

</style>