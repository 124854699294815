<template>
    <div class="inGameWrap">
        <div
            v-show="isShowChips"
            class="selectChipBg"></div>
        <div class="inGameTopBox">
            <div class="inGameTopLeftBox">
                <div class="inGameVsBar">
                    <div class="inGameProgressBarLeft">
                        <div
                            class="aniProgressBarWala"
                            v-bind:style="{ 'width': `${betUsers['list']?.['bet_info']?.['meron_dist'] || 0}%`}"></div>
                        <div class="ProgressBarHp">
                            <span> {{ `${betUsers['list']?.['bet_info']?.['meron_dist'] == 'NaN' ? '0' : betUsers['list']?.['bet_info']?.['meron_dist'] || '0' }%` }} </span>
                            <div class="inr">
                                <span
                                    class="num"
                                    v-numberFormat="calcRate(betUsers['list']?.['total_bet']?.['meron_total'] || 0, exchangeInfo)">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="inGameProgressBarRight">
                        <div
                            class="aniProgressBarMrn"
                            v-bind:style="{ 'width': `${betUsers['list']?.['bet_info']?.['wala_dist'] || 0}%` }"></div>
                        <div class="ProgressBarHp">
                            <span> {{ `${betUsers['list']?.['bet_info']?.['wala_dist'] == 'NaN' ? '0' : betUsers['list']?.['bet_info']?.['wala_dist'] || '0' }%` }} </span>
                            <div class="inr">
                                <span
                                    class="num"
                                    v-numberFormat="calcRate(betUsers['list']?.['total_bet']?.['wala_total'] || 0, exchangeInfo)">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="inGameMidBox">
            <SBingameBetlistModalM
                v-if="modals['betList']"
                v-bind:exchangeInfo="exchangeInfo"/>
            <div class="inGameMidLeft">
                <div class="videoWrap">
                    <SabongCam v-bind:vendor="route.params.gameType"/>
                    <div class="betBtnBox">
                        <!--                        <img src="@/assets/sabong/mobile/icon/icon_menu.png">-->
                        <!--                        <img
                            v-on:mouseup.stop="showModal('skin', true)"
                            src="@/assets/sabong/mobile/icon/btn_uiskin.png">-->
                        <img
                            v-on:mouseup.stop="showModal('sabongHistory', true)"
                            src="@/assets/icons/ingame_top_menu/btn-history.png">
                        <img
                            v-on:mouseup.stop="router.push('/lobby')"
                            src="@/assets/sabong/mobile/icon/icon_lobby.png">
                    </div>
                    <div
                        v-if="currentGameResult"
                        class="inGameResultBox"
                        v-bind:class="currentGameResult">
                        <h1>{{ $t(setBetLocale.text[currentGameResult]) }}</h1>
                        <div
                            class="resultPriceBox"
                            v-if="currentGameResult != 'cancel'">
                            <span v-numberFormat="realWinAmount"></span>
                        </div>
                        <div
                            class="cancelResultWrap"
                            v-else>
                            <div>
                                {{ $t('cancelInfo1') }} ({{ currentOdds }})
                            </div>
                            <div>
                                {{ $t('cancelInfo2') }}
                            </div>
                            <div
                                class="resultBox"
                                v-bind:class="currentSubRooster">
                                {{ currentSubRooster }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="inGameBetBox">
                <div
                    class="battingBlock"
                    v-if="loginUser.balance == 0">
                    {{ $t('noBalance') }}
                </div>
                <div
                    class="messageBox"
                    v-bind:class="getVinusSabongRoomStatus(sabongInfo['rooms'])['tx']">
                    <p>{{ getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] }}</p>
                </div>
                <div class="userPlayInfo">
                    <ul>
                        <li>
                            <span>Balance</span>
                            <span v-numberFormat="loginUser['balance']"></span>
                        </li>
                        <li>
                            <span>Bet</span>
                            <span v-numberFormat="userBets['total']"></span>
                        </li>
                    </ul>
                </div>
                <div class="mrnBetBoardWrap">
                    <div class="mrnBetBoardBox">
                        <div
                            class="betBoard meronBoard">
                            <p>
                                {{ $t(setBetLocale.text.meron) }}
                                <span>x{{ Number(betUsers['list']?.['bet_info']?.['meron_odd']) || commision }}</span>
                            </p>
                            <div class="betPlusMainus">
                                <div class="betPlMaBox">
                                    <span><img
                                        v-bind:class="{ 'on': clickedBoardKey === 'meron' && clickMinus }"
                                        v-on:touchstart.stop="btnMinus"
                                        src="@/assets/icons/minus.png"
                                        alt="meron"></span>
                                    <input
                                        type="text"
                                        v-model="userBets['meron']"
                                        readonly>
                                    <span><img
                                        v-bind:class="{ 'on': clickedBoardKey === 'meron' && clickPlus}"
                                        v-on:touchstart.stop="btnPlus"
                                        src="@/assets/icons/plus.png"
                                        alt="meron"></span>
                                </div>
                            </div>
                        </div>
                        <div class="betAddBox">
                            <div class="betAdd meronAdd">
                                <span
                                    v-for="chip in chips['Pro']"
                                    v-bind:key="chip"
                                    v-bind:class="{'active' : activeChip['meron'] == chip['key']}"
                                    v-on:click="clickChip(chip, 'meron')">{{ chip['tx'] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="walaBetBoardWrap">
                    <div class="walaBetBoardBox">
                        <div
                            class="betBoard walaBoard">
                            <p>
                                {{ $t(setBetLocale.text.wala) }}
                                <span>x{{ Number(betUsers['list']?.['bet_info']?.['wala_odd']) || commision }}</span>
                            </p>
                            <div class="betPlusMainus">
                                <div class="betPlMaBox">
                                    <span><img
                                        v-bind:class="{ 'on': clickedBoardKey === 'wala' && clickMinus}"
                                        v-on:touchstart.stop="btnMinus"
                                        src="@/assets/icons/minus.png"
                                        alt="wala"></span>
                                    <input
                                        type="text"
                                        v-model="userBets['wala']">
                                    <span><img
                                        v-bind:class="{ 'on': clickedBoardKey === 'wala' && clickPlus}"
                                        v-on:touchstart.stop="btnPlus"
                                        src="@/assets/icons/plus.png"
                                        alt="wala"></span>
                                </div>
                            </div>
                        </div>
                        <div class="betAddBox">
                            <div class="betAdd walaAdd">
                                <span
                                    v-for="chip in chips['Pro']"
                                    v-bind:key="chip"
                                    v-bind:class="{'active' : activeChip['wala'] == chip['key']}"
                                    v-on:click="clickChip(chip, 'wala')">{{ chip['tx'] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    v-bind:disabled="isApiCalling"
                    v-bind:style="{ 'opacity': isApiCalling ? '0.7' : '1' }"
                    v-on:click.stop="clickBetConfirm()"
                    class="betListBtnWrap confirm">
                    {{ $t('confirm') }}
                    <span class="betCountdown">
                        ({{ betCountDown }})
                    </span>
                </button>
                <button
                    class="betListBtnWrap cancel"
                    v-bind:disabled="isApiCalling"
                    v-bind:style="{ 'opacity': isApiCalling ? '0.7' : '1' }"
                    v-on:click.stop="clickBetCancel()">
                    {{ $t('cancel') }}
                </button>
                <div
                    class="betListBtnWrap"
                    v-on:mouseup.stop="showModal('betList', true)">
                    <span>{{ $t('betList') }}</span>
                    <div>
                        <!--위에 '99'는 총 참여 인원 / 아래 '9' 는 베팅에 참여한 인원입니다-->
                        <span>{{ (betUsers['list']?.['meron']?.length ? betUsers['list']?.['meron']?.length : 0) + (betUsers['list']?.['wala']?.length ? betUsers['list']?.['wala'].length : 0) }}</span>
                        <img src="@/assets/sabong/mobile/icon/icon_user.png">
                    </div>
                </div>
            </div>
        </div>

        <div class="inGameBtmBox">
            <div class="inGameTabBox">
                <ul>
                    <li
                        v-on:click="selectRoadMap = 0"
                        v-bind:class="{ 'select': selectRoadMap == 0}">
                        <span></span>
                        <p>Bead Road</p>
                    </li>
                    <!-- <li
                        v-on:click="selectRoadMap = 1"
                        v-bind:class="{ 'select': selectRoadMap == 1}">
                        <span></span>
                        <p>Big Road</p>
                    </li> -->
                    <!-- <li
                        v-on:click="selectRoadMap = 2"
                        v-bind:class="{ 'select': selectRoadMap == 2}">
                        <span></span>
                        <p>Trend</p>
                    </li> -->
                </ul>
            </div>
            <div
                class="scoreboardBox one"
                v-if="selectRoadMap == 0">
                <ul class="betDetailBox">
                    <li>
                        <span class="r">
                            {{ setBetLocale.roadLogo.meron }}
                        </span>
                        <span>{{ sabongRoadMap[gameType]["count"]["meron"] }}</span>
                    </li>
                    <li>
                        <span class="b">
                            {{ setBetLocale.roadLogo.wala }}
                        </span>
                        <span>{{ sabongRoadMap[gameType]["count"]["wala"] }}</span>
                    </li>
                    <li>
                        <span class="d">
                            {{ setBetLocale.roadLogo.draw }}
                        </span>
                        <span>{{ sabongRoadMap[gameType]["count"]["draw"] }}</span>
                    </li>
                    <li>
                        <span class="c">
                            {{ setBetLocale.roadLogo.cancel }}
                        </span>
                        <span>{{ sabongRoadMap[gameType]["count"]["cancel"] }}</span>
                    </li>
                </ul>
                <div class="scoreboardInner">
                    <BaseRoad v-bind:gameType="gameType"/>
                </div>
            </div>
            <!-- <div
                class="scoreboardBox two"
                v-if="selectRoadMap == 1">
                <ul class="betDetailBox">
                    <li>
                        <img src="@/assets/sabong/rin-m.png">
                        <span>{{ $t(setBetLocale.text.meron) }}</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/ring-w.png">
                        <span>{{ $t(setBetLocale.text.wala) }}</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/ring-d.png">
                        <span>{{ $t(setBetLocale.text.draw) }}</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/ring-c.png">
                        <span>{{ $t(setBetLocale.text.cancel) }}</span>
                    </li>
                </ul>
                <div class="scoreboardInner">
                    <BigRoad v-bind:gameType="gameType"/>
                </div>
            </div> -->
            <!-- <div
                class="scoreboardBox thr"
                v-if="selectRoadMap == 2">
                <ul class="betDetailBox">
                    <li>
                        <img src="@/assets/sabong/l-text.png">
                        <span>Llamado</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/d-text.png">
                        <span>Dehado</span>
                    </li>
                    <li class="wide">
                        <img src="@/assets/sabong/p-text.png">
                        <span>Parehasan</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/c-text.png">
                        <span>Cancel</span>
                    </li>
                </ul>
                <div class="scoreboardInner">
                    <BigRoadText v-bind:gameType="gameType"/>
                </div>
            </div> -->
            <p class="betTime">
                <span>{{ $t('fightNo') }} {{ getVinusSabongFightNo(sabongInfo["rooms"]) }} {{ $t('gmt-ist') }} {{ moment().format("YYYY-MM-DD") }}  {{ currentTime["time"] }}</span>
            </p>
        </div>
        <!--스킨 선택 모달창은 sabong/popup/SBingameThemeModal.veu 파일로 분리하였습니다.-->

        <!--betlist 모달창은 sabong/popup/SBingameBetlistModalM.vue 파일로 분리하였습니다./ betListBtnWrap 클릭시 팝업되는 모달창입니다.-->
    </div>
    <SBingameThemeModalM
        v-if="modals['skin']"/>
    <SabongModalHistoryM
        v-if="modals['sabongHistory']"
        v-on:showModal="showModal"/>
</template>
<script setup>
    import { chips, loginUser, topics, sabongInter } from '@/js/reactive';
    import router from '@/router';
    import { useRoute } from "vue-router";
    import { getCookie } from "@/js/utils";
    import { SABONG_GAME_TYPE_IDX, LOGO } from "@/js/const";
    import {setCurrencyInfo, getFlagImage, setCommisionInfo} from "@/js/common";
    import {
        setCurrencyChip,
        getGameResult,
        sabongInfo,
        generateSabongInfo,
        isShowBtnInterface,
        clearBetData,
        initData2,
        userBets,
        betUsers,
        getBetUsers,
        getSabongEvents,
        bet,
        getVinusSabongRoomStatus,
        getVinusSabongFightNo,
        isApiCalling
    } from "@/js/inGame/sabongInter";
    import { onMounted, onBeforeMount, onUnmounted, reactive, ref , watchEffect, watch, computed } from "vue";
    import moment from "moment-timezone";
    import { getBalance, getPing } from "@/js/request";
    import { generateSabongScoreBoard, sabongRoadMap } from "@/js/scoreBoard/generator";
    import { MQTT } from "@/plugins/mqtt";
    import { modals, showModal } from "@/js/modal/modals";
    import { calcRate } from '@/js/inGame/exchange';

    import BaseRoad from "@/components/scoreBoard/BaseRoad";
    import BigRoad from "@/components/scoreBoard/BigRoad";
    import BigRoadText from "@/components/scoreBoard/BigRoadText";
    import BetList from "@/components/sabongInter/BetListM";
    import InGameIcons from "@/components/common/InGameIcons";
    import SabongCam from "@/components/common/SabongCam";
    import SabongModalHistoryM from "@/components/sabong/popup/SabongModalHistoryM";
    import SBingameThemeModalM from "@/components/sabong/popup/SBingameThemeModalM";
    import SBingameBetlistModalM from "@/components/sabong/popup/SBingameBetlistModalM";
    /*	https://vuejsexamples.com/creating-an-animated-number-counter-in-vue3 */
    import Vue3autocounter from 'vue3-autocounter';
    import { useStore } from "vuex";

    const store = useStore();
    const route = useRoute();
    const clickedBoardKey   = ref(undefined);
    const clickPlus = ref(false);
    const clickMinus = ref(false);
    const recentResult = ref(undefined);
    const rootWrap = ref(undefined);
    const currentFightNo = ref(undefined);
    const currentGameResult = ref(undefined);
    const currentSubRooster = ref(undefined);
    const currentOdds = ref (undefined);
    const momentTimer          = ref(undefined);
    const realWinAmount = ref(0);
    const isBetStart = ref(false);
    const currentTime = reactive({
        'date': undefined,
        'time': undefined
    });

    const isttz = "Asia/Kolkata";
    momentTimer.value = setInterval(() => {
        currentTime["date"] = moment(moment.tz(moment(), isttz)).format("YYYY-MM-DD");
        currentTime["time"] = moment(moment.tz(moment(), isttz)).format("HH:mm:ss");
    }, 1000);
    const gameType = route.params.gameType == 'pcc' ? 'vinusPcc' : route.params.gameType;
    const gameIdx = SABONG_GAME_TYPE_IDX[gameType];
    let prevEventId = ref(undefined);
    // 로그인한 유저의 CMS에 등록된 환율 정보
    const exchangeInfo = ref(undefined);
    const betLocaleText = { meron: 'red', wala: 'blue' };

    const setBetLocale = LOGO[location.hostname].betLocale;

    watchEffect(async () => {

        if(sabongInfo['rooms']?.['event_id']){
            prevEventId.value = sabongInfo['rooms']?.['event_id'];
        }

        if(sabongInfo['rooms']){
            let betStatus = getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'];
            isBetStart.value =  betStatus == 'open' || betStatus == 'lastCall' ? true : false;
            if(currentFightNo.value && sabongInfo['rooms']['fight_no'] != currentFightNo.value){
                initData2();
            }
            currentFightNo.value = sabongInfo['rooms']['fight_no'];
        }
        // 서버 토픽 게임IDx
        if(topics?.[`sabong_result_${gameIdx}`]){
            currentGameResult.value = topics[`sabong_result_${gameIdx}`]?.['rooster'] || '';
            currentSubRooster.value = topics[`sabong_result_${gameIdx}`]?.['subRooster'] || '';
            currentOdds.value = `${setBetLocale.text.meron}: ${topics[`sabong_result_${gameIdx}`]?.['meron_odd']} ${setBetLocale.text.wala}: ${topics[`sabong_result_${gameIdx}`]?.['wala_odd']}`
            if(topics?.[`sabong_result_${gameIdx}`]?.user){
                let find = topics[`sabong_result_${gameIdx}`]['user']?.[getCookie('loginId')];
                if(find){
                    realWinAmount.value = find['win_amount'] > 0 ? Number(find['win_amount'].toFixed(2)) : -Number((find['bet_amount']).toFixed(2));
                }
            }
            await getGameResult(gameType, prevEventId.value);
            let timer = setTimeout(async () => {
                currentGameResult.value = undefined;
                currentSubRooster.value = undefined;
                currentOdds.value = undefined;
                realWinAmount.value = 0;
                delete topics[`sabong_result_${gameIdx}`];
                generateSabongScoreBoard(sabongInfo['history'], gameType);
                clearTimeout(timer);
            }, 5000);
        }
    });

    watch(isBetStart, (newVal, oldVal) => {
        if(newVal == false && oldVal == true){
            isBetStart.value = false;
        }
    });

    const betKey = ref("");
    const isShowChips = ref(false);
    const reqTimer =  ref(undefined);
    const timer = {};
    const selectChipKey = ref(undefined);
    let commision = ref(0);
    const selectChip = reactive({
        'meron' : {'chip' : {}},
        'wala' : {'chip' : {}}
    });

    const activeChip = reactive({
        'meron' : undefined,
        'wala' : undefined,
    });
    const selectRoadMap = ref(0);

    const clickChip = (chip, type) => {
        if (!isBetStart.value) {
            return;
        }
        active(chip, type);
        selectChipKey.value = type;
        selectChip[selectChipKey.value]['chip'] = {...chip};
    }

    const active = (chip, type) => {
        activeChip[type] = chip['key'];
    }

    const btnMinus = async (e) => {
        clickMinus.value = true;
        clickedBoardKey.value = e.target.alt.indexOf('meron') > -1 ? 'meron' : 'wala';
        selectChip[clickedBoardKey.value]['chip']['value'] = -Math.abs(selectChip[clickedBoardKey.value]['chip']['value']);
        await bet(selectChip[clickedBoardKey.value], clickedBoardKey.value);

        setTimeout(() => {
            clickedBoardKey.value = undefined;
            clickMinus.value = false;
        }, 250);
    }

    const btnPlus = async (e) => {
        clickPlus.value = true;
        clickedBoardKey.value = e.target.alt.indexOf('meron') > -1 ? 'meron' : 'wala';
        selectChip[clickedBoardKey.value]['chip']['value'] = Math.abs(selectChip[clickedBoardKey.value]['chip']['value']);
        await bet(selectChip[clickedBoardKey.value], clickedBoardKey.value);

        setTimeout(() => {
            clickedBoardKey.value = undefined;
            clickPlus.value = false;
        }, 250);
    };

    const betCountDown = ref(5);
    let betCountDownTimer = undefined;
    const clearBetCountDown = () => {
        clearInterval(betCountDownTimer);
        betCountDownTimer = undefined;
        betCountDown.value = 5;
    }
    const clickBetConfirm = async () => {
        if(userBets['meron'] + userBets['wala'] + userBets['draw'] <= 0 || betCountDownTimer){
            return;
        }
        betCountDown.value -=1;
        betCountDownTimer = setInterval(async () => {
            if(betCountDown.value == 1){
                await bet('','',true, false,false);
                clearBetCountDown();
                return;
            }
            betCountDown.value -=1;
        }, 1000);
    }

    const clickBetCancel = () => {
        clearBetCountDown();
        clearBetData();
    }

    const showChips = () => {
        isShowChips.value = !isShowChips.value;
    }

    const showBetList = () => {
        showModal('betList', true);
    }

    onBeforeMount(async () => {
        await getSabongEvents();
        generateSabongInfo(gameType);
        exchangeInfo.value = await setCurrencyInfo();
        commision.value = await setCommisionInfo(gameIdx);
        setCurrencyChip(route.params.gameType, 'Pro');
        if(sabongInfo['rooms']?.['event_id']){
            prevEventId.value = sabongInfo['rooms']?.['event_id'];
        }
        await getGameResult(gameType, prevEventId.value);
        await generateSabongScoreBoard(sabongInfo['history'], gameType);
        if(Object.keys(sabongInfo['rooms']).length > 0 && getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] != 'close'){
            await getBetUsers();
        }
        topics[`sabong_result_${gameIdx}`] = undefined;
        MQTT();
        getPing();
    });

    onMounted(async () => {
        await getSabongEvents();
        generateSabongInfo(gameType);
        timer['event'] = setInterval(async () => {
            await getSabongEvents();
            generateSabongInfo(gameType);
        }, 1000);
        timer['balance'] = setInterval( async () => {
            await getBalance(true);
        }, 3000);
        if(Object.keys(sabongInfo['rooms']).length > 0){
            await getBetUsers();
        }
        timer['betUsers'] = setInterval( async () => {
            if(Object.keys(sabongInfo['rooms']).length > 0 && getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] != 'close'){
                await getBetUsers();
            }
        }, 5000);
    });

    onUnmounted(() => {
        Object.keys(timer).forEach(key => {
            clearInterval(timer[key]);
        });
        clearInterval(reqTimer.value);
    })

    const icons = reactive([
        {
            'key': 'change',
            'img': require("@/assets/icons/ingame_top_menu/btn-tchange.png"),
        },
        {
            'key': 'history',
            'img': require("@/assets/icons/ingame_top_menu/btn-history.png"),
        },
        {
            'key': 'user',
            'img': require("@/assets/icons/ingame_top_menu/btn-user-list.png"),
        },
        {
            'key': 'chat',
            'img': require("@/assets/icons/ingame_top_menu/btn-chat.png"),
        },
        {
            'key': 'userInfo',
            'img': require("@/assets/icons/ingame_top_menu/btn-user-info.png"),
        },
        {
            'key': 'video',
            'img': require("@/assets/icons/ingame_top_menu/btn-cam.png"),
        },
        {
            'key': 'tutorial',
            'img': require("@/assets/icons/ingame_top_menu/btn-tutorial.png"),
        },
        {
            'key': 'sound',
            'img': require("@/assets/icons/ingame_top_menu/btn-sound.png"),
        },
        {
            'key': 'full',
            'img': require("@/assets/icons/ingame_top_menu/btn-full.png"),
        },
        {
            'key': 'exit',
            'img': require("@/assets/icons/ingame_top_menu/btn-exit.png"),
        },
    ]);
</script>
<style scoped>

span.betCountdown {
	margin-left: 5px;
	color: gold;
	font-size: 10px;
}

.inGameResultBox.cancel{
	background-color: rgba(32, 32, 32, 0.8);
}

.cancelResultWrap {
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	align-items: center;
	width: 100% !important;
	font-size: 10px !important;
	position: relative;
	top: 25px;
}
.cancelResultWrap .resultBox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 50px;
}

.resultBox.wala {
	background-color: #2056af;
	text-transform: uppercase;
}
.resultBox.meron {
	background-color: #b13636;
	text-transform: uppercase;
}
.resultBox.draw {
	background-color: #26813b;
	text-transform: uppercase;
}

span.active{
	opacity: 1;
	animation: chipClick 0.2s;
	clip: rect(0px, 41px, 100px, 0px);
	border: 2px solid #29FF5B !important;
}

@keyframes chipClick {
	0%{
		transform: scale(0.9);
	}
	50%{
		transform: scale(1.05);
	}
	100%{
		transform: scale(1);
	}
}

.aniProgressBarWala,
.aniProgressBarWala-leave-to{
	transition: all .5s ease;
}
.aniProgressBarWala-enter-active,
.aniProgressBarWala-leave-active{
	transition: all 1s ease;
}

.aniProgressBarMrn,
.aniProgressBarMrn-leave-to{
	transition: all .5s ease;
}
.aniProgressBarMrn-enter-active,
.aniProgressBarMrn-leave-active{
	transition: all 1s ease;
}

.aniBetProgressBar,
.aniBetProgressBar-leave-to{
	transition: all 2s ease;
}
.aniBetProgressBar-enter-active,
.aniBetProgressBar-leave-active{
	transition: all 2s ease;
}

.aniProgressBarWala{
	width: 0%;

}
.aniProgressBarMrn{
	width: 0%;
}
.inGameWrap{
	width: 100%;
	height: 100%;
}
.inGameTopBox{
	width: 100%;
	height: 28px;
	padding: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.inGameMidBox{
	width: 100%;
	height: 811px;
	position: relative;
}
.betListBtnWrap{
    position: relative;
    width: 100%;
    height: 36px;
    border: 1px solid #707070;
    background-image: linear-gradient(to bottom, #8b8b8b, #484848);
    border-radius: 6px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.betListBtnWrap.confirm {
	margin-top: 10px;
	background-image: linear-gradient(to bottom, #3f82e9, #2056af) !important;
	color: #fff !important;
}
.betListBtnWrap.cancel {
	margin-top: 10px;
	margin-bottom: 10px;
	background-image: linear-gradient(to bottom, #94a2b6, #667180) !important;
	color: #fff !important;
}

.betListBtnWrap>span{
    font-size: 16px;
}
.betListBtnWrap div{
    position: absolute;
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 7px;
    font-size: 12px;
}
.betListBtnWrap div span{
    padding-top: 7px;
}
.betListBtnWrap div img{
    padding-left: 5px;
}
.inGameBtmBox{
	width: 100%;
	height: auto;
	padding: 0 4px;
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.inGameTopLeftBox{
	width: 100%;
	height: 100%;
}
.inGameVsBar{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
    gap: 6px;
}
.inGameProgressBarRight{
    display: flex;
    align-items: center;
    position: relative;
	width: 100%;
	height: 100%;
    padding: 0 6px;
    background-color: #5a5a5c;
	border-radius: 2rem;
	overflow-x: hidden;
}
.inGameProgressBarRight > span{
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
}
.inGameProgressBarLeft .inr,
.inGameProgressBarRight .inr{
	font-size: 12px;
}
.inGameProgressBarLeft .inr .num,
.inGameProgressBarRight .inr .num{
	color: #ffce23;
}
.aniProgressBarWala{
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	background-color: #bb3a3a;
	border-radius: 0;
}
.messageBox.open {
	background-color: #12ab7c;
}
.messageBox.lastCall {
	background-color: rgba(88,11,11,.8);
}
.messageBox.close {
	background-color: gray;
}
/* lastCall box-shadow 효과 */
.lastCall{
	box-shadow: 0px 0px 10px 3px #F57D7D;
	animation: blink-1 1s infinite both;
}
@keyframes blink-1 {
	0%{
		box-shadow: none;
	}
	50%{
		box-shadow: 0px 0px 10px 3px #F57D7D;
	}
	100%{
		box-shadow: none;
	}
}
.inGameProgressBarLeft{
    display: flex;
    align-items: center;
	position: relative;
	width: 100%;
	height: 100%;
    padding: 0 6px;
	background-color: #5a5a5c;
	border-radius: 2rem;
	overflow-x: hidden;
}
.inGameProgressBarLeft > span{
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
}
.aniProgressBarMrn{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: #2056af;
}
.VsBarCnt{
	width: 30px;
	height: 100%;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	color: #fafafa;
	background-color: #d5a922;
}
.inGameTopRightBox > ul{
	display: flex;
	flex-direction: row;
	gap: 12px;
}
.inGameTopRightBox > ul > li > img{
	width: 50px;
}
.inGameMidBox{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.inGameMidLeft{
	width: 100%;
	height: 100%;
}
.videoWrap{
	position: relative;
	width: 100%;
	height: 219px;
	text-align: center;
	color: #fff;
	font-size: 20px;
	background-image: url("../../assets/sabong/cam.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.betBtnBox{
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 6px;
	margin-right: 4px;
	margin-top: 6px;
	z-index: 1;
}
.betBtnBox > img{
	width: 40px;
	vertical-align: middle;
	cursor: pointer;
}

.inGameResultBox{
	position: absolute;
	width: 100%;
	height: 90px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;
}

.inGameResultBox.meron{
	background-color: rgba(88, 11, 11, 0.8);
}
.inGameResultBox.wala{
	background-color: rgba(16, 56, 109, 0.8);
}
.inGameResultBox.draw{
	background-color: rgba(18, 100, 29, 0.8);
}

.inGameResultBox > p{
	font-size: 20px;
	font-weight: 600;
}
.inGameResultBox > div{
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 5px;
	width: 250px;
	height: 24px;
	line-height: 24px;
	font-size: 16px;
}

.inGameMidRight{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	gap: 6px;
	padding: 0 4px;
}
.inGameMidRight .inGameMidTop{
	height: 58px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 4px;
}
.userBetListMrn,
.userBetListWala
{
	position: relative;
	width: 49.4%;
	height: 60px;
}
.userBetListDraw{
	position: relative;
	width: 100%;
	height: 60px;
}
.userBetListTopBox{
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: #fff;
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	border-radius: 6px;
}
.userBetListTopBox.mrn{
	background-image: linear-gradient(to bottom, #bb3a3a, #981e1e);
	border: 1px solid  #ff516f;
}
.userBetListTopBox.wala{
	background-image: linear-gradient(to bottom, #2056af, #0d3880);
	border: 1px solid #3395d6;
}
.userBetListTopBox > h1{
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 18px;
	line-height: 16px;
	font-weight: 600;
}
.userBetListTopBox > h1 > span{
	font-size: 13px;
	font-weight: 300;
    color: #19e657;
}
.amountBox{
	width: 142px;
	height: 20px;
	border-radius: 2rem;
	text-align: center;
	line-height: 20px;
	font-size: 14px;
	color: #ffce23;
}
.mrn .amountBox{
	background-color: #371b1b;
	color: #ffce23;
}
.wala .amountBox{
	background-color: #1a1a35;
	color: #ffce23;
}
.player{
	position: absolute;
	top: 2px;
	right: 2px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1px;
	width: 42px;
	height: 14px;
	border-radius: 2rem;
	padding: 0 4px;
	background-color: #371b1b;
}
.player span{
	font-size: 10px;
}
.userPlayInfo{
	width: 100%;
	padding: 4px 0;
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.userPlayInfo ul{
	width: 100%;
	height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 4px;
}
.userPlayInfo ul > li{
	height: 20px;
	line-height: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	font-size: 12px;
    border: 1px solid #6c6c6c;
	padding: 0 4px;
}
.userPlayInfo ul > li span:nth-child(2){
	color: #ffce23;
}
.userPlayInfo ul > li:nth-child(1){
	width: 50%;
	background-color: #2b2b2c;
	border-radius: 2rem 0 0 2rem;
}
.userPlayInfo ul > li:nth-child(2){
	width: 50%;
	background-color: #2b2b2c;
	border-radius: 0 2rem 2rem 0;
}
.betProgressBar{
	width: 100%;
	height: 22px;
	border-radius: 2rem;
	position: relative;
	background-color: #00936f;
}

.betProgressBar .betRound{
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 0 2%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.betProgressBar .betRound span{
     font-size: 10px;
     color: #fff;
}
.betProgressBar>span{
    width: 100%;
    position: absolute;
    text-align: center;
    line-height: 22px;
    color: #fff;
    font-size: 14px;
}
.aniBetProgressBar{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	border-radius: 2rem 0 0 2rem;
}
.inGameBetBox{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px;
	position: relative;
}
.battingBlock {
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 7px;
	width: 100%;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	height: 190px;
	top: 50px;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	cursor: not-allowed;
}
.messageBox{
    width: 100%;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00936f;
}
.messageBox p{
    color: #fff;
    font-size: 14px;
}
.mrnBetBoardWrap, .walaBetBoardWrap{
    margin-bottom: 4px;
}
.mrnBetBoardBox,.walaBetBoardBox{
    width: 100%;
    height: 88px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.mrnBetBoardBox .betBoard.meronBoard{
    border: solid 1px #ff516f;
    background: #bb3a3a;
    outline: solid 4px transparent;
    transition: outline 0.25s;
}
.betBoard{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 5px;
    padding: 7px 12px;
    position: relative;
    height: 100%;
    width: 65%;
}
.betBoard.meronBoard.on {
    outline: solid 4px #ff4343;
    animation: click 0.20s;
}

.walaBetBoardBox .betBoard.walaBoard {
    border: solid 1px #3395d6;
    background: #1f55ae;
    outline: solid 4px transparent;
    transition: outline 0.25s;
}
.betBoard.walaBoard.on {
    outline: solid 4px #1d7dc7;
    animation: click 0.2s;
}

.betBoard.meronBoard:active,
.betBoard.walaBoard:active{
    transform: scale(0.99);
}

@keyframes click {
    0% {
        transform: scale(0.99);
    }
    50% {
        transform: scale(1.01);
    }
    100% {
        transform: scale(1);
    }
}

.mrnBetBoardBox .betBoard p, .walaBetBoardBox .betBoard p{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}
.mrnBetBoardBox .betBoard p span, .walaBetBoardBox .betBoard p span{
    font-weight: 300;
    font-size: 18px;
    color: #19e657;
}
.mrnBetBoardBox .betBoard .betBoardDetailBox{
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 26px;
    border-radius: 2rem;
    font-size: 18px;
    color: #ffce23;
    text-align: center;
    line-height: 26px;
}
.mrnBetBoardBox .betBoard.meronBoard .betBoardDetailBox{
    background-color: #371b1b;
}
.mrnBetBoardBox .betBoard.walaBoard .betBoardDetailBox{
    background-color: #1a1a35;
}
.betAddBox{
    width: 35%;
    height: 100%;
}
.betAddBox .betAdd {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
}
.betAddBox .betAdd span{
    border-radius: 6px;
    text-align: center;
    color: #fff;
    line-height: 38px;
    cursor: pointer;
    font-size: 14px;
}
.betAddBox .meronAdd span{
    background-image: linear-gradient(to bottom, #de4f4f, #781616);
    border: 1px solid #ff516f;
}
.betAddBox .walaAdd span{
    background-image: linear-gradient(to bottom, #3b74d1, #11336b);
    border: 1px solid #3395d6;
}
.meronBoard .betPlMaBox {
    background: #371b1b;
}
.walaBoard .betPlMaBox {
    background: #1a1a35;
}
.betPlusMainus{
    display: flex;
    align-items: center;
    gap: 10px;
}
.betPlusMainus>span{
    font-size: 20px;
    color: #fff;
}
.betPlusMainus .betPlMaBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;
    padding: 0 6px;
    border-radius: 6px;
}
.betPlusMainus .betPlMaBox img{
    width: 16px;
}
.betPlusMainus .betPlMaBox span{
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
}
.betPlusMainus .betPlMaBox input{
    background: none;
    font-size: 14px;
    width: 53%;
    color: #ffce70;
    text-align: center;
}
.inGameListBox > div{
	width: 50%;
	background-color: #2b2b2b;
	height: 42px;
	border: 1px solid #b7b7b7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #fafafa;
	font-weight: 300;
}
.inGameListBox > div.mrn span{
	color: #ff4a4a;
	font-size: 14px;
}
.inGameListBox > div.wala span{
	color: #5d8dff;
	font-size: 14px;
}
.inGameTabBox{
	width: 100%;
	height: auto;
}
.inGameTabBox ul{
	width: 100%;
	height: 30px;
	border-radius: 2rem;
	border: 1px solid #6c6c6c;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 0 20px;
}
.inGameTabBox ul li{
    width: 33%;
	color: #646464;
	font-size: 12px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 4px;
}
.inGameTabBox ul li.select{
	color: #ffd52d;
}
.inGameTabBox ul li span{
	display: inline-block;
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
}
.inGameTabBox ul li:nth-child(1) span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_1_g.png");
}
.inGameTabBox ul li:nth-child(2) span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_2_g.png");
}
.inGameTabBox ul li:nth-child(3) span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_3_g.png");
}
.inGameTabBox ul li:nth-child(1).select span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_1_y.png");
}
.inGameTabBox ul li:nth-child(2).select  span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_2_y.png");
}
.inGameTabBox ul li:nth-child(3).select  span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_3_y.png");
}

.scoreboardBox{
	width: 100%;
	background-color: #2b2b2c;
	border: 1px solid #484848;
	padding: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 5px;
}
.scoreboardInner{
	width: 100%;
	height: 144px;
	/*background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAABxJREFUKFNjZCAO/GckTh3DqEK8AQUOnv/EhCUAZm8KAZQzbbIAAAAASUVORK5CYII=") repeat #fff;
	background-size: 22px 22px;*/
}

.betDetailBox{
	width: 100%;
	height: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.betDetailBox li{
    width: 25%;
	color: #fff;
	font-size: 14px;
	display: grid;
    grid-auto-flow: column;
    place-items: center;
    column-gap: 2px;
}
.betDetailBox li span:first-child {
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
}
.betDetailBox li span.r {
	background-color: red;
}
.betDetailBox li span.b {
	background-color: blue;
}
.betDetailBox li span.d {
	background-color: green;
}
.betDetailBox li span.c {
	background-color: gray;
}


.betDetailBox li img{
    width: 20px;
}
.scoreboardBox.two .betDetailBox li > img{
	width: 15px;
}
.scoreboardBox.thr .betDetailBox > li.wide{
    width: 30%;
}
.scoreboardBox.thr .betDetailBox li > img{
	width: 18px;
	vertical-align: middle;
	margin-right: 3px;
}
.scoreboardBox:last-child .betDetailBox li > img{
	width: 16px;
	vertical-align: middle;
	margin-right: 3px;
}
.betTime{
    font-size: 12px;
    color: #fff;
    text-align: center;
}
.betTime span{
    font-weight: 100;
}


/* 20211111 한유정 추가 */
.sbb2,
.sbb3{
	display: none;
}
.ProgressBarHp{
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
    align-items: center;
	width: 100%;
	font-size: 14px;
    color: #fff;
}
.ProgressBarHp > span{
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
}
.ProgressBarHp .inr{
	display: flex;
	gap: 4px;
}
.betPerson{
	color: #ffce23 !important;
}

::-webkit-scrollbar {
    width: 10px;
    height: 6px !important;
}


</style>